<template>
  <div class="follow">
    <pageHead title="我的关注" />
    <div v-infinite-scroll="loadMore" class="follow_content" infinite-scroll-distance="50">
      <topic
        @like="like($event, index)"
        @favorite="favorite($event, index)"
        v-for="(item, index) in topicData"
        :key="item.id"
        :data="item"
        class="topicItem"
        @userInfoClick="handleToUserDetail(item)"
        @share="handleShare"
        @click="handleTopicClick"
        @originalClick="handleTopicClick"
        @comment="handleComment"
        @follow='handleFollow(item, index)'
      />
      <no-more
        v-if="!listQueryParams.hasNextPage"
        text="没有更多数据"
        class="no-more"
      />
    </div>
    <commentToolDialog :show-dialog.sync="showDialog" :isAnonymousComment.sync="isAnonymousComment"
                       v-model="commentContent" hide-relation hide-img
                       @publish="handlePublishComment"
                       @confirm='handleNicknameConfirm'/>
  </div>
</template>

<script>
import { myFollow } from "@/api/att";
import { addThumbsup, removeThumbsup } from "@/api/thumbsup";
import { addFavorites, removeFavorites } from "@/api/favorites";
import {addComment} from "@/api/comment";
import {attAdd, followRemove} from "@/api/att";

import pageHead from "@/components/pageHead";
import topic from "@/components/topicItem";
import NoMore from "@/components/uitls/NoMore";
import commentToolDialog from '@/components/commentToolDialog'

const ENTITY_TYPE_ARTICLE = 1

export default {
  components: {
    pageHead,
    topic,
    NoMore,
    commentToolDialog
  },
  data() {
    return {
      pageNum: 1,
      pageSize: 20,
      topicData: [],
      listQueryParams: {
        pageNum: 1,
        pageSize: 10,
        hasNextPage: true,
      },
      showDialog: false,
      isAnonymousComment: false,
      commentContent: "",
      anonymousNickname: ''
    };
  },
  methods: {
    loadMore() {
      this.loadList();
    },
    loadList() {
      let listQueryParams = this.listQueryParams;
      if (listQueryParams.hasNextPage) {
        myFollow(this.listQueryParams).then((res) => {
          listQueryParams.hasNextPage = res.current < res.pages;
          listQueryParams.pageNum = listQueryParams.pageNum + 1;
          let resData = this.topicData;
          res.records.forEach((item) => {
            let count = resData.filter((innerItem) => innerItem.id === item.id);
            if (count.length === 0) {
              resData.push(item.articleVO);
            }
          });
          this.topicData = resData;
        });
      }
    },
    like(res, index) {
      // 取消点赞
      if (res.thumbsUpFlag) {
        removeThumbsup({
          entityId: res.id,
          entityType: 1,
          // eslint-disable-next-line no-unused-vars
        }).then(() => {
          let data = this.topicData[index];
          this.topicData[index].thumbsUpFlag = !res.thumbsUpFlag;
          let count = data.relationVO.thumbsUpCount - 1;
          if (count < 0) count = 0;
          data.relationVO.thumbsUpCount = count;
        });
      } else {
        // 添加点赞
        addThumbsup({
          entityId: res.id,
          entityType: 1,
        }).then(() => {
          let data = this.topicData[index];
          this.topicData[index].thumbsUpFlag = !res.thumbsUpFlag;
          if (!data.relationVO) {
            data.relationVO = {};
          }
          data.relationVO.thumbsUpCount = data.relationVO.thumbsUpCount + 1;
        });
      }
    },

    favorite(res, index) {
      // 取消收藏
      if (res.favoritesFlag) {
        removeFavorites([res.id]).then(() => {
          let data = this.topicData[index];
          this.topicData[index].favoritesFlag = !res.favoritesFlag;
          let count = data.relationVO.favoriteCount - 1;
          if (count < 0) count = 0;
          data.relationVO.favoriteCount = count;
        });
      } else {
        // 添加收藏
        addFavorites({
          articleId: res.id,
        }).then(() => {
          let data = this.topicData[index];
          this.topicData[index].favoritesFlag = !res.favoritesFlag;
          if (!data.relationVO) {
            data.relationVO = {};
          }
          data.relationVO.favoriteCount = data.relationVO.favoriteCount + 1;
        });
      }
    },
    handleTopicClick({id} = {}) {
      // if(status === 0) {
      this.$router.push({ name: "topicDetail", query: {id} });
      // }
    },
    /**
     * 分享事件
     */
    handleShare({id: quoteId} = {}) {
      this.$router.push({name: 'createTopic', query: {quoteId}})
    },
    //跳转用户详情页
    handleToUserDetail({userId} = {}) {
      if(userId !== -1) {
        this.$store.commit('common/SET_USERID', userId);
        this.$router.push({name: 'userInfoDetail', params: {userId}})
      }
    },
    //点击评论按钮
    handleComment(item) {
      this.showDialog = true
      this.commentParams = item
    },
    //发表评论
    async handlePublishComment() {
      const {commentContent: content, commentParams: {id: entityId}, isAnonymousComment, anonymousNickname} = this
      const entityType = ENTITY_TYPE_ARTICLE
      if (!content.trim()) {
        this.$notify({message: '评论内容不能为空', type: 'danger'});
        return
      }
      const anonymous = isAnonymousComment ? 1 : 0
      await addComment({content, entityId, entityType, anonymous, anonymousNickname})
      this.showDialog = false
      this.commentParams = {}
      this.commentContent = ""
      this.topicData = this.topicData.map(item => {
        if (item.id !== entityId) {
          return item
        }
        const {relationVO} = item
        return {
          ...item,
          relationVO: {...relationVO, commentCount: relationVO.commentCount + 1}
        }
      })
    },
    handleNicknameConfirm(name) {
      this.anonymousNickname = name
    },
    async handleFollow(data, index) {
      const {id, myFollow} = data
      if(myFollow) {
        await followRemove(id)
        this.topicData[index].commonFollowCount--
      } else {
        await attAdd(id)
        this.topicData[index].commonFollowCount++
      }
      this.topicData[index].myFollow = !myFollow
    },
  },
};
</script>

<style lang="scss" scoped>
.follow {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  .follow_content {
    position: relative;
    overflow-y: auto;
    height: calc(100vh - 52px);

    .topicItem {
      margin-top: 10px;
    }

    .no-more {
      margin-top: 10px;
    }
  }
}
</style>
